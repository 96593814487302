import React, { ComponentType, Fragment, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CardJob from "@/components/CardJob/CardJob";
import CardJobSkeleton from "@/components/CardJob/CardJobSkeleton";
import Typography from "@material-ui/core/Typography";
import { apolloClient } from "@/utils/apolloClient";
import { PAGINATE_JOB_POST } from "@/graphql/job_post/query";
import { JOB_CATEGORY } from "@/graphql/job_category/query";
import { JOB_LOCATION } from "@/graphql/job_location/query";
import { formatMoneyNumberToString, getSort, graphqlFilter } from "@/helpers/string";
import { compose } from "recompose";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import JobPageType from "@/store/redux/types/JobPageType";
import { updatePage, updateCategory, updateLocation } from "@/store/redux/actions/JobPageAction";

interface IProps {
  view?: string;
  classes: any;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `0 ${theme.spacing(2)}px`,
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    pagination: {
      width: "100%",
      textAlign: "center",
      margin: `${theme.spacing(2)}px 0`,
    },
    button: {
      color: "#fff",
      fontWeight: "bold",
      width: "150px",
      height: "35px",
      borderRadius: "30px",
      margin: "0 10px",
    },
    noResult: {
      margin: "30px auto",
    },
    prevButton: {
      marginRight: theme.spacing(2),
    },
    nextButton: {},
  });

const ListSearch: ComponentType<IProps> = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const { classes } = props;
  const { keyword, jobCategory, jobLocation, jobSkill, benefit, sort, viewMode, jobs, loading, hasNextPage, page } =
    useSelector((state: any) => state.jobPage);
  console.log("jobsss", jobs);
  const dispatch = useDispatch();
  const [firstMatchUrl, setFirstMatchUrl] = useState(true);

  // const {view = "list"} = props;

  const getPage = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("page");
  };

  const handleNextPage = () => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get("page");
    let filter = {
      title: keyword,
      sort_by: getSort(sort.value),
      job_category: jobCategory._id,
      job_location: jobLocation._id,
      job_skill: jobSkill._id,
      company_benefit: benefit._id,
    };
    const filterString = graphqlFilter(filter);

    if (hasNextPage) {
      if (page) {
        query.set("page", `${parseInt(page) + 1}`);
        dispatch(updatePage(parseInt(page) + 1));
        fetchListJob(parseInt(page) + 1, filterString);
      } else {
        query.set("page", "2");
        dispatch(updatePage(2));
        fetchListJob(2, filterString);
      }
      scrollToTop();
      history.push(`${window.location.pathname}?${query}`);
    }
  };

  const handlePrevPage = () => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get("page");
    let filter = {
      title: keyword,
      sort_by: getSort(sort.value),
      job_category: jobCategory._id,
      job_location: jobLocation._id,
      job_skill: jobSkill._id,
      company_benefit: benefit._id,
    };
    const filterString = graphqlFilter(filter);

    // Chỉ cho phép quay về trang trước nếu trang hiện tại lớn hơn 1
    if (page && parseInt(page) > 1) {
      query.set("page", `${parseInt(page) - 1}`);
      dispatch(updatePage(parseInt(page) - 1));
      fetchListJob(parseInt(page) - 1, filterString);
    } else {
      dispatch(updatePage(1));
      query.delete("page");
      fetchListJob(1, filterString);
    }
    scrollToTop();
    history.push(`${window.location.pathname}?${query}`);
  };

  const fetchListJob = (page: number, filter: string) => {
    dispatch({ type: JobPageType.FETCH_JOB, page, filter });
  };

  useEffect(() => {
    if (firstMatchUrl) {
      if (getPage()) {
        dispatch(updatePage(+(getPage() as string)));
      }
      const indexCategory = location.pathname.lastIndexOf("viec-lam-");
      const indexLocation = location.pathname.indexOf("tai-");
      const valueCategory: any = location.pathname
        .slice(indexCategory, indexLocation > 0 ? indexLocation - 1 : 999)
        .split("viec-lam-")[1];
      const valueLocation: any = location.pathname.slice(indexLocation).split("tai-")[1];
      if (valueCategory || valueLocation) {
        Promise.all([
          valueCategory && apolloClient.query({ query: JOB_CATEGORY, variables: { slug: valueCategory } }),
          valueLocation && apolloClient.query({ query: JOB_LOCATION, variables: { slug: valueLocation } }),
        ]).then((res: any) => {
          if (res[0]) {
            dispatch(updateCategory(res[0].data.jobCategory));
          }
          if (res[1]) {
            dispatch(updateLocation(res[1].data.jobLocation));
          }
          setFirstMatchUrl(false);
        });
      } else {
        setFirstMatchUrl(false);
      }
    } else {
      let filter = {
        title: keyword,
        sort_by: getSort(sort.value),
        job_category: jobCategory._id,
        job_location: jobLocation._id,
        job_skill: jobSkill._id,
        company_benefit: benefit._id,
      };
      const filterString = graphqlFilter(filter);
      fetchListJob(1, filterString);
    }
  }, [sort, keyword, jobCategory, jobLocation, jobSkill, benefit, firstMatchUrl]);

  const view = viewMode;

  const renderListSearch = () => {
    return (
      <Grid container spacing={2}>
        {loading ? (
          <Fragment>
            <Grid item xs={12} sm={view === "grid" ? 6 : 12} lg={view === "grid" ? 4 : 12}>
              <CardJobSkeleton view={view} />
            </Grid>
            <Grid item xs={12} sm={view === "grid" ? 6 : 12} lg={view === "grid" ? 4 : 12}>
              <CardJobSkeleton view={view} />
            </Grid>
            <Grid item xs={12} sm={view === "grid" ? 6 : 12} lg={view === "grid" ? 4 : 12}>
              <CardJobSkeleton view={view} />
            </Grid>
            <Grid item xs={12} sm={view === "grid" ? 6 : 12} lg={view === "grid" ? 4 : 12}>
              <CardJobSkeleton view={view} />
            </Grid>
            <Grid item xs={12} sm={view === "grid" ? 6 : 12} lg={view === "grid" ? 4 : 12}>
              <CardJobSkeleton view={view} />
            </Grid>
            <Grid item xs={12} sm={view === "grid" ? 6 : 12} lg={view === "grid" ? 4 : 12}>
              <CardJobSkeleton view={view} />
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            {jobs && jobs.length > 0 ? (
              jobs.map((item: any, index: number) => {
                let {
                  _id,
                  company,
                  salary,
                  slug = "",
                  title = "",
                  view_count = "",
                  created_at = "",
                  updated_at = "",
                } = item.node;
                let { max = 0, min = 0, show = false } = salary || {};
                let { logo = "", name = "", office = [] } = company.ref || {};
                let address = office.length > 0 ? office[0].address : "Chưa có địa chỉ";
                let job = {
                  _id: _id,
                  title: title,
                  slug: slug,
                  image: logo,
                  name,
                  salary: show
                    ? `${formatMoneyNumberToString(min)}.000.000 ₫ - ${formatMoneyNumberToString(max)}.000.000 ₫`
                    : "Thương lượng",
                  like_status: false,
                  view_count: view_count,
                  created_at: created_at,
                  updated_at: updated_at,
                  address: address,
                };
                return (
                  <Grid
                    key={index}
                    item
                    xs={view === "grid" ? 6 : 12}
                    xl={view === "grid" ? 4 : 12}
                    sm={view === "grid" ? 6 : 12}
                    md={view === "grid" ? 6 : 12}
                    lg={view === "grid" ? 4 : 12}
                  >
                    <CardJob view={view} job={job} />
                  </Grid>
                );
              })
            ) : (
              <Typography variant={"h4"} className={classes.noResult}>
                Không có kết quả nào
              </Typography>
            )}
          </Fragment>
        )}
      </Grid>
    );
  };

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={classes.root}>
      {renderListSearch()}
      <div className={classes.pagination}>
        {getPage() && (
          <Button
            className={classes.prevButton}
            onClick={handlePrevPage}
            disabled={page === 1}
            variant={"outlined"}
            color={"primary"}
          >
            Trang trước
          </Button>
        )}
        {hasNextPage && (
          <Button
            className={classes.nextButton}
            onClick={handleNextPage}
            disabled={!hasNextPage}
            variant={"outlined"}
            color={"primary"}
          >
            Trang sau
          </Button>
        )}
      </div>
    </div>
  );
};

export default compose<IProps, any>(withStyles(styles))(ListSearch);
