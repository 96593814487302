import React, {ComponentType, useState} from "react";
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Input from "@material-ui/core/Input/Input";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import {compose} from "recompose";
import {apolloClient} from "@/utils/apolloClient";
import {PAGINATE_SUGGESTION} from "@/graphql/suggestion/query";
import ReactLink from "@/components/Link/ReactLink";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

type IProps = {
  classes?: any;
  statusModal: boolean;
  onClose: () => void;
}
const styles = (theme: Theme) => createStyles({
  buttonKeyword: {
    margin: 7,
    borderRadius: 20,
  },
});

const SearchModal: ComponentType<IProps> = (props: IProps) => {
  const {classes} = props;

  const [resultKeywords, setResultKeywords] = useState<string[]>([]);
  const [keyword, setKeyword] = useState("");
  const [timerId, setTimerId] = useState<any>(null);
  const onChangeKeyword = (event: any) => {
    let key = event.target.value;
    setKeyword(key);
    clearTimeout(timerId);
    let newTime = setTimeout(() => {
      fetchResult(key);
    }, 600);
    setTimerId(newTime);
    if (event.key == "Enter" || event.keyCode == 13) {
      onClickSearch();
    }
  };
  const onClickSearch = () => {

  };

  const fetchResult = (key: string) => {
    if (key) {
      apolloClient.query({query: PAGINATE_SUGGESTION, variables: {page: 1, limit: 4, filter: `{'title': '${key}'}`}})
        .then(r => {
          setResultKeywords(r.data.suggestions.edges);
        })
    } else {
      setResultKeywords([]);
    }
  };

  return (
    <React.Fragment>
      <div className="header__mb__search">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={props.statusModal}
          className="searchModal_mb"
        >
          <div className="searchModal">
            <div onClick={props.onClose}>
              <IconButton className="searchModal__closeIcon">
                <KeyboardBackspaceIcon/>
              </IconButton>
            </div>
            <Input
              autoFocus={true}
              className="searchModal__input"
              placeholder="Tìm kiếm"
              disableUnderline={true}
              onChange={e => onChangeKeyword(e)}
            />
            <div className="searchModal__keywordList">
              <Typography component="h3" className="keywordList__title">
                {keyword ? "Có phải bạn đang tìm" : "Từ khóa gần đây"}
              </Typography>
              <List>
                {
                  resultKeywords.map((item: any, index: number) => {
                    return (
                      <ReactLink href={""} key={index}><ListItem><ListItemText>{item.node.title}</ListItemText></ListItem></ReactLink>
                    )
                  })
                }
              </List>
              <Typography component="h3" className="keywordList__title">
                Từ khóa phổ biến
              </Typography>
              <Button
                className={classes.buttonKeyword}
                size="small"
                variant="outlined"
              >
                Sale(Bán hàng)
              </Button>
              <Button
                className={classes.buttonKeyword}
                size="small"
                variant="outlined"
              >
                Tư vấn
              </Button>
              <Button
                className={classes.buttonKeyword}
                size="small"
                variant="outlined"
              >
                Ngân hàng(Ngân hàng)
              </Button>
              <Button
                className={classes.buttonKeyword}
                size="small"
                variant="outlined"
              >
                IT(Phần mềm)
              </Button>
              <Button
                className={classes.buttonKeyword}
                size="small"
                variant="outlined"
              >
                Sale(Bán hàng)
              </Button>
              <Button
                className={classes.buttonKeyword}
                size="small"
                variant="outlined"
              >
                Marketing(Bán hàng)
              </Button>
              <Button
                className={classes.buttonKeyword}
                size="small"
                variant="outlined"
              >
                Ngân hàng(Ngân hàng)
              </Button>
              <Button
                className={classes.buttonKeyword}
                size="small"
                variant="outlined"
              >
                IT(Phần mềm)
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default compose<IProps, any>(
  withStyles(styles),
)(SearchModal);
