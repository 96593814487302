import gql from "graphql-tag";

export const PAGINATE_JOB_LOCATION = gql`
  query jobLocations($page: Int!, $limit: Int!, $filter: String!) {
    jobLocations(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          seo_title
          seo_description
        }
      }
    }
  }
`;

export const JOB_LOCATION = gql`
  query jobLocation($slug: String!) {
    jobLocation(slug: $slug) {
      _id
      seo_title
      seo_description
    }
  }
`;
