import React, { ComponentType, useEffect, useState, Fragment } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import LoginModal from "@/components/Modal/Login";
import SearchModal from "@/components/Modal/Search";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Popper from "@material-ui/core/Popper";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { isWidthDown } from "@material-ui/core/withWidth";
import Typography from "@material-ui/core/Typography";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { compose } from "recompose";
import { withCookies } from "react-cookie";
import Cookies from "universal-cookie";
import AddCircle from "@material-ui/icons/AddCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import ListAlt from "@material-ui/icons/ListAlt";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ReactLink from "@/components/Link/ReactLink";
import Popover from "@material-ui/core/Popover";
import withWidth from "@material-ui/core/withWidth";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import { apolloClient } from "@/utils/apolloClient";
import { PAGINATE_SUGGESTION } from "@/graphql/suggestion/query";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import WorkIcon from "@material-ui/icons/Work";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PageviewIcon from "@material-ui/icons/Pageview";
import BusinessIcon from "@material-ui/icons/Business";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import logo from "../../assets/images/full-logo black.svg";
import { GET_USER } from "@/graphql/user/query";
// import { getCookie } from "@/utils/cookie";
// import Cookies from "universal-cookie";
import Axios from "axios";

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarItem: {
      marginRight: theme.spacing(1),
    },
    typography: {
      padding: theme.spacing(2),
    },
    inline: {
      display: "inline",
    },

    popper: {
      zIndex: 1290,
    },
    buttonKeyword: {
      margin: 7,
      borderRadius: 20,
    },
    clearHeader: {
      paddingTop: "85px",
    },
    appBar: {
      boxShadow: "none",
      padding: "0 32px",
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  });

interface IProps {
  classes: any;
}

interface ILocalProps extends IProps {
  cookies: Cookies;
  width: any;
}

const Header: ComponentType<ILocalProps> = (props: ILocalProps) => {
  const { classes, cookies } = props;

  const [anchorSearchEl, setAnchorSearchEl] = React.useState<HTMLInputElement | null>(null);
  const handleOpenSearchPopper = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorSearchEl(event.currentTarget);
  };
  const handleCloseSearchPopper = () => {
    setAnchorSearchEl(null);
  };
  const openSearchPopper = Boolean(anchorSearchEl);
  const idSearchPopper = openSearchPopper ? "search-popper" : undefined;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const token = cookies.get("knv_accessToken");
    console.log("tokn đây", token);
    if (token) {
      apolloClient
        .query({
          query: GET_USER,
          variables: { token },
        })
        .then((response) => {
          setUser(response.data.user);
        });
    }
  }, []);

  const logoutTrigger = async () => {
    try {
      await Axios.post(
        process.env.REACT_APP_API_URL + `/auth/user/logout`,
        {},
        {
          withCredentials: true,
        }
      );

      cookies.remove("knv_accessToken", {
        path: "/",
      });

      window.location.reload();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const loginButtonClick = () => {
    handleOpenLoginModal(true);
  };

  const [anchorDrawer, setAnchorDrawer] = useState(false);
  const toggleDrawer = (anchor: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setAnchorDrawer(open);
  };

  const [resultKeywords, setResultKeywords] = useState<string[]>([]);
  const [keyword, setKeyword] = useState("");
  const [timerId, setTimerId] = useState<any>(null);
  const onChangeKeyword = (event: any) => {
    let key = event.target.value;
    setKeyword(key);
    clearTimeout(timerId);
    let newTime = setTimeout(() => {
      fetchResult(key);
    }, 600);
    setTimerId(newTime);
    if (event.key == "Enter" || event.keyCode == 13) {
      onClickSearch();
    }
  };
  const onClickSearch = () => {};

  const fetchResult = (key: string) => {
    if (key) {
      apolloClient
        .query({ query: PAGINATE_SUGGESTION, variables: { page: 1, limit: 4, filter: `{'title': '${key}'}` } })
        .then((r) => {
          setResultKeywords(r.data.suggestions.edges);
        });
    } else {
      setResultKeywords([]);
    }
  };

  const [statusSearchModal, setStatusSearchModal] = useState(false);
  const handleOpenSearchModal = (status: boolean) => {
    setStatusSearchModal(status);
  };

  const [statusLoginModal, setStatusLoginModal] = useState(false);
  const handleOpenLoginModal = (status: boolean) => {
    setStatusLoginModal(status);
  };

  const handleFocusSearchArea = (e: any) => {
    if (isWidthDown("sm", props.width)) {
      handleOpenSearchModal(true);
    } else {
      handleOpenSearchPopper(e);
    }
  };

  const handleClickSearchArea = (e: any) => {
    if (isWidthDown("sm", props.width)) {
      handleOpenSearchModal(true);
    }
  };
  return (
    <Fragment>
      <AppBar position={"fixed"} className={classes.appBar}>
        <div className="header">
          <LoginModal statusModal={statusLoginModal} onClose={() => handleOpenLoginModal(false)} />
          <SearchModal statusModal={statusSearchModal} onClose={() => handleOpenSearchModal(false)} />
          <div className="header__left" style={{ width: "85px" }}>
            <div className="header_logo">
              <ReactLink url="/">
                <img src={logo} alt="" />
              </ReactLink>
            </div>
          </div>
          <div className="header__center">
            <div className="header__center__search">
              <div className="header__center__search__icon">
                <svg viewBox="0 0 512 512">
                  <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
                </svg>
              </div>
              <div className="header__center__search__box">
                <InputBase
                  placeholder={"Tìm kiếm"}
                  onChange={(e) => onChangeKeyword(e)}
                  aria-describedby={idSearchPopper}
                  onFocus={handleFocusSearchArea}
                  onClick={handleClickSearchArea}
                  onBlur={handleCloseSearchPopper}
                />
                <Popper
                  className={classes.popper}
                  id={idSearchPopper}
                  placement={"bottom-start"}
                  open={openSearchPopper}
                  anchorEl={anchorSearchEl}
                >
                  <div className="header__search__keywordList">
                    <div className="keyword__recent">
                      <Typography component="h3" className="keywordList__title">
                        {keyword ? "Có phải bạn đang tìm" : "Từ khóa gần đây"}
                      </Typography>
                      <List>
                        {resultKeywords.map((item: any, index: number) => {
                          return (
                            <ReactLink href={""} key={index}>
                              <Typography variant={"body1"}>{item.node.title}</Typography>
                            </ReactLink>
                          );
                        })}
                      </List>
                    </div>
                    <div className="keyword__list">
                      <Typography component="h3" className="keywordList__title">
                        Từ khóa phổ biến
                      </Typography>
                      <Button className={classes.buttonKeyword} size="small" variant="outlined">
                        Sale(Bán hàng)
                      </Button>
                      <Button className={classes.buttonKeyword} size="small" variant="outlined">
                        Tư vấn
                      </Button>
                      <Button className={classes.buttonKeyword} size="small" variant="outlined">
                        Ngân hàng(Ngân hàng)
                      </Button>
                      <Button className={classes.buttonKeyword} size="small" variant="outlined">
                        IT(Phần mềm)
                      </Button>
                      <Button className={classes.buttonKeyword} size="small" variant="outlined">
                        Sale(Bán hàng)
                      </Button>
                      <Button className={classes.buttonKeyword} size="small" variant="outlined">
                        Marketing(Bán hàng)
                      </Button>
                      <Button className={classes.buttonKeyword} size="small" variant="outlined">
                        Ngân hàng(Ngân hàng)
                      </Button>
                      <Button className={classes.buttonKeyword} size="small" variant="outlined">
                        IT(Phần mềm)
                      </Button>
                    </div>
                  </div>
                </Popper>
              </div>
              <Button className="header__center__search__button" variant="outlined" onClick={onClickSearch}>
                <svg viewBox="0 0 512 512">
                  <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
                </svg>
              </Button>
            </div>
          </div>
          <div className="header__right">
            <Hidden smDown>
              {user && (
                <a href={process.env.REACT_APP_STUDIO_URL}>
                  <Button className="header_button _full" variant="outlined">
                    Nhà tuyển dụng
                  </Button>
                </a>
              )}

              {!user && (
                <Button className="header_button _full" variant="outlined" onClick={loginButtonClick}>
                  Nhà tuyển dụng
                </Button>
              )}

              {user ? (
                <div>
                  <Button className="header_button _no_padding" aria-describedby={id} onClick={handleClick}>
                    <Avatar alt={user.first_name} src={user.avatar} />
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    className="header_button__popup"
                  >
                    <Typography component={"span"}>
                      <ReactLink url={process.env.REACT_APP_ME_URL}>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar className="header_button__popup__avatar">
                            <Avatar alt={user.first_name} src={user.avatar} />
                          </ListItemAvatar>
                          <ListItemText
                            className="header_button__popup__title"
                            primary={`${user.first_name} ${user.last_name}`}
                            secondary={
                              <React.Fragment>
                                <Typography component="span" variant="body2" color="textPrimary">
                                  {user.email}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </ReactLink>
                      <Divider />
                      <ReactLink url={process.env.REACT_APP_ME_URL}>
                        <ListItem button>
                          <ListItemIcon>
                            <AssignmentIndIcon />
                          </ListItemIcon>
                          <ListItemText primary="Quản lý hồ sơ cá nhân" />
                        </ListItem>
                      </ReactLink>
                      <ReactLink url={process.env.REACT_APP_MY_JOB_URL}>
                        <ListItem button>
                          <ListItemIcon>
                            <WorkIcon />
                          </ListItemIcon>
                          <ListItemText primary="Việc làm của tôi" />
                        </ListItem>
                      </ReactLink>
                      <ReactLink url={process.env.REACT_APP_JOB_NOTIFICATION_URL}>
                        <ListItem button>
                          <ListItemIcon>
                            <NotificationsActiveIcon />
                          </ListItemIcon>
                          <ListItemText primary="Thông báo việc làm" />
                        </ListItem>
                      </ReactLink>
                      <ReactLink url={process.env.REACT_APP_PROFILE_VIEW_URL}>
                        <ListItem button>
                          <ListItemIcon>
                            <PageviewIcon />
                          </ListItemIcon>
                          <ListItemText primary="Nhà tuyển dụng xem hồ sơ" />
                        </ListItem>
                      </ReactLink>
                      <Divider />
                      <ReactLink url={process.env.REACT_APP_SETTING_URL}>
                        <ListItem button>
                          <ListItemIcon>
                            <SettingsIcon />
                          </ListItemIcon>
                          <ListItemText primary="Cài đặt tài khoản" />
                        </ListItem>
                      </ReactLink>
                      <ListItem button onClick={() => logoutTrigger()}>
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Đăng xuất" />
                      </ListItem>
                    </Typography>
                  </Popover>
                </div>
              ) : (
                <Button className="header_button _border" variant="outlined" onClick={loginButtonClick}>
                  Đăng nhập
                </Button>
              )}
              <ReactLink url={process.env.REACT_APP_COMPANY_URL}>
                <Button
                  className={
                    "header_button _empty" +
                    (process.env.REACT_APP_COMPANY_URL &&
                    window.location.pathname.includes(process.env.REACT_APP_COMPANY_URL)
                      ? " active"
                      : "")
                  }
                  variant="outlined"
                >
                  Công ty
                </Button>
              </ReactLink>
              <ReactLink url={process.env.REACT_APP_JOB_CATE_URL}>
                <Button
                  className={
                    "header_button _empty" +
                    (process.env.REACT_APP_JOB_CATE_URL &&
                    window.location.pathname.includes(process.env.REACT_APP_JOB_CATE_URL)
                      ? " active"
                      : "")
                  }
                  variant="outlined"
                >
                  Việc làm
                </Button>
              </ReactLink>
              <ReactLink url={process.env.REACT_APP_CV_URL}>
                <Button
                  className={
                    "header_button _empty" +
                    (process.env.REACT_APP_CV_URL && window.location.pathname.includes(process.env.REACT_APP_CV_URL)
                      ? " active"
                      : "")
                  }
                  variant="outlined"
                >
                  Mẫu CV
                </Button>
              </ReactLink>
              {/* <ReactLink url={process.env.REACT_APP_STATISTIC_URL}>
                <Button
                  className={
                    "header_button _empty" +
                    (process.env.REACT_APP_STATISTIC_URL &&
                    window.location.pathname.includes(process.env.REACT_APP_STATISTIC_URL)
                      ? " active"
                      : "")
                  }
                  variant="outlined"
                >
                  Thị trường
                </Button>
              </ReactLink> */}
            </Hidden>
            <Hidden mdUp>
              <IconButton
                edge="start"
                className="header_button _menu"
                aria-label="open drawer"
                onClick={toggleDrawer("right", true)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </div>
          <Drawer anchor="right" open={anchorDrawer} onClose={toggleDrawer("right", false)}>
            <div className="header__menu_mobile">
              <Typography className="header__menu_mobile__body">
                {user && (
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar className="header__menu_mobile__avatar">
                      <Avatar alt={user.first_name} src={user.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      className="header__menu_mobile__title"
                      primary={`${user.first_name} ${user.last_name}`}
                      secondary={
                        <React.Fragment>
                          <Typography component="span" variant="body2" color="textPrimary">
                            {user.email}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                )}

                {!user && (
                  <ReactLink url={process.env.REACT_APP_ME_URL}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar className="header__menu_mobile__avatar">
                        <Avatar alt="Khách ghé thăm" src="https://static.nhadatmoi.net/default/user.png" />
                      </ListItemAvatar>
                      <ListItemText
                        className="header__menu_mobile__title"
                        primary="Chào mừng Khách"
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                              {process.env.REACT_APP_NAME}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </ReactLink>
                )}
                <Divider />

                <ReactLink url={process.env.REACT_APP_STATISTIC_URL}>
                  <ListItem button>
                    <ListItemIcon>
                      <EqualizerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Thị trường" />
                  </ListItem>
                </ReactLink>

                <ReactLink url={process.env.REACT_APP_CV_URL}>
                  <ListItem button>
                    <ListItemIcon>
                      <EqualizerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mẫu CV" />
                  </ListItem>
                </ReactLink>

                <ReactLink url={process.env.REACT_APP_JOB_CATE_URL}>
                  <ListItem button>
                    <ListItemIcon>
                      <ListAlt />
                    </ListItemIcon>
                    <ListItemText primary="Việc làm" />
                  </ListItem>
                </ReactLink>

                <ReactLink url={process.env.REACT_APP_COMPANY_URL}>
                  <ListItem button>
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Công ty" />
                  </ListItem>
                </ReactLink>

                {user && (
                  <a href={process.env.REACT_APP_STUDIO_URL}>
                    <ListItem button>
                      <ListItemIcon>
                        <AddCircle />
                      </ListItemIcon>
                      <ListItemText primary="Nhà tuyển dụng" />
                    </ListItem>
                  </a>
                )}

                {!user && (
                  <ListItem button onClick={loginButtonClick}>
                    <ListItemIcon>
                      <AddCircle />
                    </ListItemIcon>
                    <ListItemText primary="Nhà tuyển dụng" />
                  </ListItem>
                )}
                {!user && (
                  <ListItem button onClick={loginButtonClick}>
                    <ListItemIcon>
                      <ArrowForward />
                    </ListItemIcon>
                    <ListItemText primary="Đăng nhập" />
                  </ListItem>
                )}

                {user && (
                  <ReactLink url={process.env.REACT_APP_ME_URL}>
                    <ListItem button>
                      <ListItemIcon>
                        <AssignmentIndIcon />
                      </ListItemIcon>
                      <ListItemText primary="Quản lý hồ sơ cá nhân" />
                    </ListItem>
                  </ReactLink>
                )}
                {user && (
                  <ReactLink url={process.env.REACT_APP_MY_JOB_URL}>
                    <ListItem button>
                      <ListItemIcon>
                        <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Việc làm của tôi" />
                    </ListItem>
                  </ReactLink>
                )}
                {user && (
                  <ReactLink url={process.env.REACT_APP_JOB_NOTIFICATION_URL}>
                    <ListItem button>
                      <ListItemIcon>
                        <NotificationsActiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Thông báo việc làm" />
                    </ListItem>
                  </ReactLink>
                )}
                {user && (
                  <ReactLink url={process.env.REACT_APP_PROFILE_VIEW_URL}>
                    <ListItem button>
                      <ListItemIcon>
                        <PageviewIcon />
                      </ListItemIcon>
                      <ListItemText primary="Nhà tuyển dụng xem hồ sơ" />
                    </ListItem>
                  </ReactLink>
                )}

                {user && <Divider />}
                {user && (
                  <ReactLink url={process.env.REACT_APP_SETTING_URL}>
                    <ListItem button>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Cài đặt tài khoản" />
                    </ListItem>
                  </ReactLink>
                )}
                {user && (
                  <ListItem button onClick={() => logoutTrigger()}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Đăng xuất" />
                  </ListItem>
                )}
              </Typography>
            </div>
          </Drawer>
        </div>
      </AppBar>
      <div className={classes.clearHeader} />
    </Fragment>
  );
};
export default compose<ILocalProps, any>(withCookies, withWidth(), withStyles(styles))(Header);
