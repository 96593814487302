import React, { MouseEvent, useContext, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconFilter from "@/assets/images/icons/filter-mobile.svg";
import Image from "@/components/Image/Image";
import Filter from "@/views/JobPage/Content/Filter";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import JobPageType from "@/store/redux/types/JobPageType";
import { updateSort, updateView } from "@/store/redux/actions/JobPageAction";

interface IProps {
  category?: ICategory;
}

interface ICategory {
  select?: object;
}

interface IMenuSort {
  id: string;
  value: string;
  text: string;
}

type Anchor = "top" | "left" | "bottom" | "right";

const DATA: IMenuSort[] = [
  { id: "1", value: "created_newest", text: "Mới nhất" },
  { id: "2", value: "created_oldest", text: "Cũ nhất" },
  { id: "3", value: "salary_low_to_high", text: "Lương tăng dần" },
  { id: "4", value: "salary_high_to_low", text: "Lương giảm dần" },
  { id: "5", value: "view_count_low_to_high", text: "Lượt xem tăng dần" },
  { id: "6", value: "view_count_high_to_low", text: "Lượt xem giảm dần" },
  { id: "7", value: "rating_high_to_low", text: "Đánh giá cao nhất" },
];

const HeaderSearch = (props: IProps) => {
  const history = useHistory();
  const { number, jobCategory, viewMode } = useSelector((state: any) => state.jobPage);
  const dispatch = useDispatch();
  const [viewModeGrid, setViewModeGrid] = useState(false);
  const [viewModeList, setViewModeList] = useState(true);
  const [openSort, setOpenSort] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchor, setAnchor] = useState<Anchor>("right");
  const [sort, setSort] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const sort = query.get("sort_by");
    if (sort) {
      const indexSort = DATA.findIndex((item) => item.value === sort);
      setSort(DATA[indexSort].text);
    }
    switch (query.get("view")) {
      case "grid":
        setViewModeGrid(true);
        setViewModeList(false);
        break;
      case "list":
        setViewModeGrid(false);
        setViewModeList(true);
        break;
      default:
        break;
    }
  }, [window.location.href]);

  const handleChangeGridView = () => {
    const query = new URLSearchParams(window.location.search);
    query.set("view", "grid");
    history.push(`${process.env.REACT_APP_JOB_CATE_URL}?${query}`);
    dispatch(updateView("grid"));
  };

  const handleChangeListView = () => {
    const query = new URLSearchParams(window.location.search);
    query.set("view", "list");
    history.push(`${process.env.REACT_APP_JOB_CATE_URL}?${query}`);
    dispatch(updateView("list"));
  };

  const handleToggleSort = (event: MouseEvent<HTMLElement>) => {
    setOpenSort(!openSort);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenSort(false);
    setAnchorEl(null);
  };

  const handleSelect = (item: IMenuSort) => () => {
    const query = new URLSearchParams(window.location.search);
    query.delete("page");
    query.set("sort_by", item.value);
    setOpenSort(false);
    setAnchorEl(null);
    history.push(`${process.env.REACT_APP_JOB_CATE_URL}?${query}`);
    dispatch(
      updateSort({
        value: item.value,
        text: item.text,
      })
    );
  };

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
    setAnchor(anchor);
  };
  return (
    <div className="header_search_page">
      <b className="title_search_page">
        <Typography variant="h2">
          Tìm việc làm {jobCategory.title} ({number})
        </Typography>
        <span className="count--mobile">
          <span className="count--mobile__num">{number}</span> việc làm phù hợp
        </span>
      </b>
      <div className="view__mode">
        <div className="view__mode__left">
          <div className="view__mode__icon">
            <Button style={{ minWidth: "auto" }} onClick={handleChangeGridView}>
              <AppsOutlinedIcon style={{ color: viewModeGrid ? "#3cb4a3" : "#555555", fontSize: 30 }} />
            </Button>
            <Button style={{ minWidth: "auto" }} onClick={handleChangeListView}>
              <FormatListBulletedIcon style={{ color: viewModeList ? "#3cb4a3" : "#555555", fontSize: 30 }} />
            </Button>
          </div>
          <>
            <ListItem
              aria-controls="sort-search"
              aria-haspopup="true"
              className="view__mode__sort"
              button
              onClick={handleToggleSort}
            >
              <span>{sort || "Mới nhất"}</span>
              {openSort ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Menu
              id="sort-search"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              classes={{ list: "view__mode__sort__menu" }}
            >
              {DATA.map((item, index) => (
                <MenuItem key={item.id} onClick={handleSelect(item)}>
                  {item.text}
                </MenuItem>
              ))}
            </Menu>
          </>
        </div>
        <div className="filter__mobile" onClick={toggleDrawer("right", true)}>
          <span className="filter__mobile__text">Bộ lọc</span>
          <Image className="filter__mobile__icon" src={IconFilter} />
        </div>
      </div>
      <Drawer
        classes={{
          paper: "drawer__search__page__paper",
        }}
        anchor={anchor}
        open={openDrawer}
        onClose={toggleDrawer("right", false)}
      >
        <Filter />
      </Drawer>
    </div>
  );
};

export default HeaderSearch;
