import JobPageType, { JobPageState } from "@/store/redux/types/JobPageType";
import { updateObject } from "@/store/utility";

const defaultState: JobPageState = {
  keyword: "",
  sort: {
    value: "created_newest",
    text: "Mới nhất",
  },
  jobCategory: {
    _id: "",
    en_title: "",
    vi_title: "",
  },
  jobLocation: {
    _id: "",
    en_title: "",
    vi_title: "",
  },
  jobSkill: {
    _id: "",
    title: "",
  },
  benefit: {
    _id: "",
    en_title: "",
    vi_title: "",
  },
  number: 0,
  viewMode: "list",
  jobs: [],
  page: 1,
  loading: false,
  hasNextPage: false,
};

export const jobPageReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobPageType.UPDATE_KEYWORD:
      return updateObject<JobPageState>(state, action.payload);
    case JobPageType.UPDATE_JOB_CATEGORY:
      return updateObject<JobPageState>(state, action.payload);
    case JobPageType.UPDATE_JOB_LOCATION:
      return updateObject<JobPageState>(state, action.payload);
    case JobPageType.UPDATE_JOB_SKILL:
      return updateObject<JobPageState>(state, action.payload);
    case JobPageType.UPDATE_BENEFIT:
      return updateObject<JobPageState>(state, action.payload);
    case JobPageType.UPDATE_SORT:
      return updateObject<JobPageState>(state, action.payload);
    case JobPageType.UPDATE_NUMBER:
      return updateObject<JobPageState>(state, action.payload);
    case JobPageType.UPDATE_VIEW_MODE:
      return updateObject<JobPageState>(state, action.payload);
    case JobPageType.FETCHING_JOB:
      return { ...state, loading: true };
    case JobPageType.FETCH_JOB_SUCCESS:
      return updateObject<JobPageState>(state, action.payload);
    default:
      return state;
  }
};
