import React, {Component} from "react";
import {Route} from "react-router-dom";
import NProgress from "nprogress";

export default class FancyRouter extends Component<{}> {
  componentWillMount() {
    NProgress.configure({trickleSpeed: 50});
    NProgress.configure({easing: "ease", speed: 700});
    NProgress.configure({minimum: 0.1});
    NProgress.inc(0.2);
    NProgress.start();
    NProgress.set(0.4);
  }

  componentDidMount() {
    NProgress.set(1.0);
    NProgress.done();
  }

  render() {
    return <Route {...this.props} />;
  }
}
