import React from "react";
import Image from "@/components/Image/Image";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import {compose} from "recompose";

const styles = (theme: Theme) => createStyles({
  root: {

  },
  image: {
    width: "100%",
    marginBottom: theme.spacing(2),
  }
});

interface IProps {
  classes: any;
}

const Advertisement = (props: IProps) => {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <Image
        src="https://images.pexels.com/photos/2529787/pexels-photo-2529787.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        className={classes.image}
      />
      <Image
        src="https://images.pexels.com/photos/1058100/pexels-photo-1058100.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        className={classes.image}
      />
      <Image
        src="https://images.pexels.com/photos/317140/pexels-photo-317140.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        className={classes.image}
      />
    </div>
  );
};

export default compose<IProps, any>(
  withStyles(styles)
)(Advertisement);
