import gql from "graphql-tag";

export const PAGINATE_BENEFIT = gql`
  query benefits($page: Int!, $limit: Int!, $filter: String!) {
    benefits(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          title
          seo_title
        }
      }
    }
  }
`;
