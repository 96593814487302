import React, { ComponentType, useContext, useEffect, useState } from "react";
import { compose } from "recompose";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash/debounce";
import { IJobFilterContext, JobFilterContext } from "@/store/context/pages/Job/JobFilterContext";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { apolloClient } from "@/utils/apolloClient";
import { PAGINATE_JOB_CATEGORY } from "@/graphql/job_category/query";
import { graphqlFilter } from "@/helpers/string";
import { PAGINATE_JOB_LOCATION } from "@/graphql/job_location/query";
import { PAGINATE_BENEFIT } from "@/graphql/benefit/query";
import { PAGINATE_JOB_SKILL } from "@/graphql/job_skill/query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import JobPageType from "@/store/redux/types/JobPageType";
import {
  updateKeyword,
  updateCategory,
  updateLocation,
  updateSkill,
  updateBenefit,
} from "@/store/redux/actions/JobPageAction";

interface IProps {
  classes: any;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    filterHeading: {},
    filterKeyword: {
      marginTop: theme.spacing(1),
    },
    filterJobCategory: {
      marginTop: theme.spacing(1),
    },
    filterJobLocation: {
      marginTop: theme.spacing(1),
    },
    filterJobSkill: {
      marginTop: theme.spacing(1),
    },
    filterBenefit: {
      marginTop: theme.spacing(1),
    },
  });

const Filter: ComponentType<IProps> = (props: IProps) => {
  const { classes } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { jobCategory, jobLocation } = useSelector((state: any) => state.jobPage);
  const [jobCategoryOptions, setJobCategoryOptions] = useState<any[]>([]);
  const [jobLocationOptions, setJobLocationOptions] = useState<any[]>([]);
  const [jobSkillOptions, setJobSkillOptions] = useState<any[]>([]);
  const [benefitOptions, setBenefitOptions] = useState<any[]>([]);

  useEffect(() => {
    fetchJobCategory();
    fetchJobLocation();
    fetchBenefit();
    fetchJobSkill();
  }, []);

  useEffect(() => {
    if (location.pathname === process.env.REACT_APP_JOB_CATE_URL) {
      dispatch({
        type: JobPageType.UPDATE_JOB_CATEGORY,
        value: {
          _id: "",
          en_title: "",
          vi_title: "",
        },
      });
      dispatch({
        type: JobPageType.UPDATE_JOB_LOCATION,
        value: {
          _id: "",
          en_title: "",
          vi_title: "",
        },
      });
    }
  }, [location.pathname]);

  const fetchJobCategory = (keyword = "") => {
    let filter = {
      title: keyword,
    };
    let filterString = graphqlFilter(filter);
    apolloClient
      .query({ query: PAGINATE_JOB_CATEGORY, variables: { page: 1, limit: 10, filter: filterString } })
      .then((r) => {
        if (r.data.jobCategorys.edges) {
          setJobCategoryOptions(r.data.jobCategorys.edges);
        }
      });
  };

  const fetchJobLocation = (keyword = "") => {
    let filter = {
      title: keyword,
    };
    let filterString = graphqlFilter(filter);
    apolloClient
      .query({ query: PAGINATE_JOB_LOCATION, variables: { page: 1, limit: 10, filter: filterString } })
      .then((r) => {
        if (r.data.jobLocations.edges) {
          setJobLocationOptions(r.data.jobLocations.edges);
        }
      });
  };

  const fetchBenefit = (keyword = "") => {
    let filter = {
      title: keyword,
    };
    let filterString = graphqlFilter(filter);
    apolloClient
      .query({ query: PAGINATE_BENEFIT, variables: { page: 1, limit: 20, filter: filterString } })
      .then((r) => {
        if (r.data.benefits.edges) {
          setBenefitOptions(r.data.benefits.edges);
        }
      });
  };

  const fetchJobSkill = (keyword = "") => {
    let filter = {
      title: keyword,
    };
    let filterString = graphqlFilter(filter);
    apolloClient
      .query({ query: PAGINATE_JOB_SKILL, variables: { page: 1, limit: 12, filter: filterString } })
      .then((r) => {
        if (r.data.jobSkills.edges) {
          setJobSkillOptions(r.data.jobSkills.edges);
        }
      });
  };

  const debounceSearch = debounce((value) => {
    dispatch(updateKeyword(value));
  }, 300);

  const handleChangeKeyword = (event: any) => {
    let value = event.target.value;
    debounceSearch(value);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => option.node.title,
  });

  return (
    <div className={classes.root}>
      <div className={classes.filterHeading}>
        <Typography variant={"body1"}>
          <strong>Lọc kết quả tìm kiếm</strong>
        </Typography>
      </div>
      <div className={classes.filterKeyword}>
        <TextField label="Từ khóa" fullWidth onChange={handleChangeKeyword} />
      </div>
      <div className={classes.filterJobCategory}>
        <Autocomplete
          filterOptions={filterOptions}
          fullWidth
          id="filterJobCategory"
          value={jobCategory}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label={"Ngành nghề"}
              onChange={(e) => {
                fetchJobCategory(e.target.value);
              }}
              fullWidth
            />
          )}
          onChange={(event: any, value: any) => {
            if (value) {
              const indexLocation = location.pathname.indexOf("tai-");
              if (indexLocation > 0) {
                history.replace(
                  `${process.env.REACT_APP_JOB_CATE_URL}-viec-lam-${value.node.slug}-${location.pathname.slice(
                    indexLocation
                  )}`
                );
              } else {
                history.replace(`${process.env.REACT_APP_JOB_CATE_URL}-viec-lam-${value.node.slug}`);
              }
              dispatch(updateCategory(value.node));
            } else {
              const indexLocation = location.pathname.indexOf("tai-");
              if (indexLocation > 0) {
                history.replace(`${process.env.REACT_APP_JOB_CATE_URL}-${location.pathname.slice(indexLocation)}`);
              } else {
                history.replace(`${process.env.REACT_APP_JOB_CATE_URL}`);
              }
              dispatch(
                updateCategory({
                  _id: "",
                  title: "",
                })
              );
            }
          }}
          options={jobCategoryOptions}
          getOptionLabel={(option: any) => option.node?.title || option.title}
        />
      </div>
      <div className={classes.filterJobLocation}>
        <Autocomplete
          filterOptions={filterOptions}
          fullWidth
          id="filterJobLocation"
          value={jobLocation}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label={"Khu vực"}
              onChange={(e) => {
                fetchJobLocation(e.target.value);
              }}
              fullWidth
            />
          )}
          onChange={(event: any, value: any) => {
            if (value) {
              const indexCategory: any = location.pathname.match(/viec-lam/g);
              if (indexCategory && indexCategory.length > 1) {
                history.replace(
                  `${process.env.REACT_APP_JOB_CATE_URL}-viec-lam-${jobCategory.slug}-tai-${value.node.slug}`
                );
              } else {
                history.replace(`${process.env.REACT_APP_JOB_CATE_URL}-tai-${value.node.slug}`);
              }
              dispatch(updateLocation(value.node));
            } else {
              const indexCategory: any = location.pathname.match(/viec-lam/g);
              if (indexCategory && indexCategory.length > 1) {
                history.replace(`${process.env.REACT_APP_JOB_CATE_URL}-viec-lam-${jobCategory.slug}`);
              } else {
                history.replace(`${process.env.REACT_APP_JOB_CATE_URL}`);
              }
              dispatch(
                updateLocation({
                  _id: "",
                  title: "",
                })
              );
            }
          }}
          options={jobLocationOptions}
          getOptionLabel={(option: any) => option.node?.vi_title || option.vi_title}
        />
      </div>
      <div className={classes.filterJobSkill}>
        <Autocomplete
          filterOptions={filterOptions}
          fullWidth
          id="filterJobSkill"
          renderInput={(params: any) => (
            <TextField
              {...params}
              label={"Kỹ năng"}
              onChange={(e) => {
                fetchJobSkill(e.target.value);
              }}
              fullWidth
            />
          )}
          onChange={(event: any, value: any) => {
            if (value) {
              dispatch(updateSkill(value.node));
            } else {
              dispatch(
                updateSkill({
                  _id: "",
                  title: "",
                })
              );
            }
          }}
          options={jobSkillOptions}
          getOptionLabel={(option: any) => option.node.title}
        />
      </div>
      <div className={classes.filterBenefit}>
        <Autocomplete
          filterOptions={filterOptions}
          fullWidth
          id="filterBenefit"
          renderInput={(params: any) => <TextField {...params} label={"Phúc lợi"} fullWidth />}
          onChange={(event: any, value: any) => {
            if (value) {
              dispatch(updateBenefit(value.node));
            } else {
              dispatch(
                updateBenefit({
                  _id: "",
                  title: "",
                })
              );
            }
          }}
          options={benefitOptions}
          getOptionLabel={(option: any) => option.node.title}
        />
      </div>
    </div>
  );
};

export default compose<IProps, any>(withStyles(styles))(Filter);
