import React, { ComponentType } from "react";
import theme from "@/theme";
import RouteList from "@/routes/Main";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Cookies from "universal-cookie";
import withWidth from "@material-ui/core/withWidth/withWidth";
import { History } from "history";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { GlobalContext } from "@/store/context/GlobalContext";
import { RouteChildrenProps, RouterProps } from "react-router";
// import ReactGA from 'react-ga';
import "@/assets/scss/main.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "@/i18n";
import GlobalModal from "@/components/Modal/GlobalModal";
import GlobalToast from "@/components/Toast/GlobalToast";
import { SnackbarProvider } from "notistack";

interface AppProps extends RouterProps, RouteChildrenProps {
  history: History;
  width: Breakpoint;
  cookies: Cookies;
}

// const initializeReactGA = () => {
//   ReactGA.initialize('UA-134989606-1');
//   ReactGA.pageview(
//     `/${window.location.pathname} + ${
//       window.location.search
//     }`
//   );
// }

const cookieRefresher = () => {
  const cookies = new Cookies();
  let token = cookies.get("knv_accessTokenss");
  let tokenExpires = cookies.get("token_expires");

  if (token && !tokenExpires) {
    cookies.set("knv_accessTokenss", token, {
      maxAge: 1800,
      path: "/",
    });
  }
};

// @ts-ignore
const App: ComponentType<{}, any> = (props: AppProps) => {
  const { history, location, width, match, cookies } = props;
  let user = cookies.get("knv_accessToken");
  // cookieRefresher();
  // initializeReactGA();
  return (
    <I18nextProvider i18n={i18n}>
      <MuiThemeProvider theme={theme}>
        <GlobalContext.Provider value={{ history, location, width, match, user }}>
          <GlobalModal />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <GlobalToast />
          </SnackbarProvider>
          <RouteList />
        </GlobalContext.Provider>
      </MuiThemeProvider>
    </I18nextProvider>
  );
};

export default compose(withRouter, withWidth(), withCookies)(App);
