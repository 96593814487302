import {Link} from "react-router-dom";
import React from "react";

export default function ReactLink({children, url, ...rest}: any) {
  if (typeof url === 'undefined') {
    url = '';
  }
  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
}
