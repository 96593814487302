import gql from "graphql-tag";

export const PAGINATE_JOB_CATEGORY = gql`
  query jobCategorys($page: Int!, $limit: Int!, $filter: String!) {
    jobCategorys(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          title
          slug
          seo_title
          seo_description
        }
      }
    }
  }
`;

export const JOB_CATEGORY = gql`
  query jobCategory($slug: String!) {
    jobCategory(slug: $slug) {
      _id
      title
      slug
      seo_title
      seo_description
    }
  }
`;
