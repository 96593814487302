import React, { ComponentType, Fragment } from "react";
import { compose } from "recompose";
import { withStyles, createStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
interface IProps {
  classes: any;
  children: any;
  contained: boolean;
  fullWidth: boolean;
  noPadding: boolean;
}
const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      maxWidth: 1600,
      margin: "0 auto",
    },
    fullWidth: {
      paddingLeft: 32,
      paddingRight: 32,
      margin: "auto",
      [theme.breakpoints.down('md')]: {
        paddingLeft: 16,
        paddingRight: 16,
      }
    },
    noPadding: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  });
const WrapperContainer: ComponentType<IProps> = (props: IProps) => {
  const { classes, contained, fullWidth, noPadding, children } = props;

  return (
    <Fragment>
      <div
        className={classNames(
          contained && !fullWidth && classes.container,
          fullWidth && !contained && classes.fullWidth,
          !contained && !fullWidth && classes.container,
          noPadding && classes.noPadding,
        )}
      >
        {children}
      </div>
    </Fragment>
  );
};
export default compose<IProps, any>(withStyles(styles))(WrapperContainer);
