enum JobPageType {
  FETCH_JOB = "@@JobPage/FETCH_JOB",
  FETCHING_JOB = "@@JobPage/FETCHING_JOB",
  FETCH_JOB_SUCCESS = "@@JobPage/FETCH_JOB_SUCCESS",
  UPDATE_SORT = "@@JobPage/UPDATE_SORT",
  UPDATE_KEYWORD = "@@JobPage/UPDATE_KEYWORD",
  UPDATE_JOB_CATEGORY = "@@JobPage/UPDATE_JOB_CATEGORY",
  UPDATE_JOB_LOCATION = "@@/JobPage/UPDATE_JOB_LOCATION",
  UPDATE_JOB_SKILL = "@@/JobPage/UPDATE_JOB_SKILL",
  UPDATE_BENEFIT = "@@/JobPage/UPDATE_BENEFIT",
  UPDATE_NUMBER = "@@JobPage/UPDATE_NUMBER",
  UPDATE_VIEW_MODE = "@@/JobPage/UPDATE_VIEW_MODE",
  UPDATE_PAGE = "@@/JobPage/UPDATE_VIEW_MODE",
}

interface JobPageState {
  readonly keyword: string;
  readonly sort: { value: string; text: string };
  readonly jobCategory: { _id: string; vi_title: string; en_title: string };
  readonly jobLocation: { _id: string; vi_title: string; en_title: string };
  readonly jobSkill: { _id: string; title: string };
  readonly benefit: { _id: string; vi_title: string; en_title: string };
  readonly number: number;
  readonly viewMode: "grid" | "list";
  readonly loading: boolean;
  readonly hasNextPage: boolean;
  readonly jobs: any[];
  readonly page: number;
}
export default JobPageType;
export { JobPageState };
