import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import App from "@/App";
import * as serviceWorker from "@/serviceWorker";
import "@/index.css";
import { Provider } from "react-redux";

import store from "@/store/redux/store";

export const windowExist = typeof window !== "undefined";

const AppRoot = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </BrowserRouter>
    </Provider>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (!windowExist) {
  ReactDOM.hydrate(<AppRoot />, document.getElementById("root"));
} else {
  ReactDOM.render(<AppRoot />, document.getElementById("root"));
  serviceWorker.register();
}
