import React, { ComponentType } from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { compose } from "recompose";
import ReactLink from "@/components/Link/ReactLink";
import Typography from "@material-ui/core/Typography";
import { cloudFrontImageUrl } from "@/helpers/string";

const styles = (theme: Theme) => ({
  root: {
    maxWidth: 345,
    border: "none",
    boxShadow: "none",
  },
  media: {
    height: 180,
  },
  description: {
    color: "#888",
  },
});

interface IProps {
  classes: any;
  image: any;
  title: string;
  location: string;
  company_name: string;
  salary: string;
  slug: string;
  created_at: string;
  updated_at: string;
  view_count: number;
  like_status: boolean;
}

const JobSlideGridItem: ComponentType<IProps> = (props: IProps) => {
  const { classes } = props;
  console.log("tét", props.location);
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <ReactLink url={`${process.env.REACT_APP_JOB_CATE_URL}/${props.slug}`}>
          <CardMedia className={classes.media} image={cloudFrontImageUrl(props.image)} title={props.title} />
          <CardContent>
            <Typography variant="h5">{props.title}</Typography>
            <Typography variant="body1" className={classes.description}>
              {props.company_name}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              Địa chỉ: {props.location}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              Mức lương: {props.salary}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              Lượt xem: {props.view_count}
            </Typography>
          </CardContent>
        </ReactLink>
      </CardActionArea>
    </Card>
  );
};

export default compose<IProps, any>(withStyles(styles))(JobSlideGridItem);
