import JobPageType from "@/store/redux/types/JobPageType";
import { takeLatest, put, all, fork } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import { PAGINATE_JOB_POST } from "@/graphql/job_post/query";
import { fetchingJob, fetchJobSuccess, updateNumber } from "@/store/redux/actions/JobPageAction";

function* fetchJob(action: any) {
  const { page, filter, type } = action;
  yield put(fetchingJob({ loading: true }));
  const r = yield apolloClient.query({
    query: PAGINATE_JOB_POST,
    variables: { page, limit: 5, filter },
  });
  if (r.data.jobPosts.edges) {
    let payload = {
      jobs: r.data.jobPosts.edges,
      loading: false,
      hasNextPage: r.data.jobPosts.pageInfo.hasNextPage,
    };
    yield put(fetchJobSuccess(payload));
    yield put(updateNumber(r.data.jobPosts.pageInfo.length));
  }
}

function* watchFetchJob() {
  yield takeLatest(JobPageType.FETCH_JOB, fetchJob);
}

function* JobPageSaga() {
  yield all([fork(watchFetchJob)]);
}

export default JobPageSaga;
