import React, { ComponentType, useEffect, useReducer, useState, Fragment } from "react";
import ListSearch from "@/views/JobPage/Content/ListSearch";
import Filter from "@/views/JobPage/Content/Filter";
import Header from "@/layouts/Main/Header";
import Footer from "@/layouts/Main/Footer";
import WrapperContainer from "@/components/WrapperContainer/WrapperContainer";
import HeaderSearch from "@/views/JobPage/Content/HeaderSearch";
import Advertisement from "@/views/JobPage/Content/Advertisement";
import { compose } from "recompose";
import { createStyles, Theme, withStyles } from "@material-ui/core";
import ListSlideJob from "@/views/JobPage/Content/ListSlideJob";
import {
  IJobFilterReducer,
  JobFilterContext,
  JobFilterReducer,
  JobFilterState,
  JobFilterStateInit,
} from "@/store/context/pages/Job/JobFilterContext";

interface IProps {
  classes: any;
  jobFilterStateInit?: JobFilterState;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "1600px",
      margin: "auto",
    },
    slideJob: {},
    filterJob: {
      marginTop: theme.spacing(2),
    },
    content: {
      display: "flex",
      width: "100%",
    },
    filterSearch: {
      width: "250px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    listSearch: {
      width: "calc(100% - 250px - 250px)",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 250px)",
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(100%)",
      },
    },
    advertisement: {
      width: "250px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  });

const Job: ComponentType<IProps> = (props: IProps) => {
  const { classes, jobFilterStateInit } = props;
  const [view, setView] = useState(new URLSearchParams(window.location.search).get("view") || "list");

  useEffect(() => {}, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("view") && query.get("view") !== view) {
      switch (query.get("view")) {
        case "grid":
          setView("grid");
          break;
        case "list":
          setView("list");
          break;
        default:
          break;
      }
    }
  }, [window.location.href]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 599 && view !== "list") {
        setView("list");
      } else if (window.innerWidth > 599 && view !== new URLSearchParams(window.location.search).get("view")) {
        setView((new URLSearchParams(window.location.search).get("view") as string) || view);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [view]);

  const getPage = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("page") ? parseInt(query.get("page") as string) : query.get("page");
  };

  const [state, dispatch] = useReducer<IJobFilterReducer>(
    JobFilterReducer,
    jobFilterStateInit ? jobFilterStateInit : JobFilterStateInit
  );

  return (
    <Fragment>
      <Header />
      <div className={classes.root}>
        <WrapperContainer>
          <div className={classes.slideJob}>
            <ListSlideJob />
          </div>
          <div className={classes.filterJob}>
            <JobFilterContext.Provider value={{ state, dispatch }}>
              <HeaderSearch />
              <div className={classes.content}>
                <div className={classes.filterSearch}>
                  <Filter />
                </div>
                <div className={classes.listSearch}>
                  <ListSearch view={view} />
                </div>
                <div className={classes.advertisement}>
                  <Advertisement />
                </div>
              </div>
            </JobFilterContext.Provider>
          </div>
        </WrapperContainer>
      </div>
      <Footer />
    </Fragment>
  );
};

export default compose<IProps, any>(withStyles(styles))(Job);
