import gql from "graphql-tag";

export const PAGINATE_JOB_SKILL = gql`
  query jobSkills($page: Int!, $limit: Int!, $filter: String!) {
    jobSkills(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          title
        }
      }
    }
  }
`;
