import gql from "graphql-tag";

export const GET_USER = gql`
  query user {
    user {
      _id
      email
      first_name
      last_name
      birth_day
      avatar
      gender
      login_type
      spam
      customize_info {
        cover
        avatar
        files
        first_name
        last_name
        current_job_title
        current_job_company
        current_job_level {
          _id
        }
        current_experience_number
        phone
        birthday
        nation
        gender
        status
        specific_address
        intro
        skill {
          _id
          title
        }
        language {
          lang
          level
        }
        employment_history {
          position
          company
          from_month
          to_month
          description
        }
        education_history {
          subject
          school
          qualification
          from_month
          to_month
          achievement
        }
        work_preference {
          job_location {
            _id
          }
          job_category {
            _id
            title
          }
          job_level {
            _id
            title
          }
          salary
          benefit {
            _id
            title
          }
        }
      }
    }
  }
`;
