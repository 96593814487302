import {Dispatch, createContext, Reducer} from "react";
import {updateObject} from "@/store/utility";
import {
  UPDATE_BENEFIT,
  UPDATE_JOB_CATEGORY, UPDATE_JOB_LOCATION, UPDATE_JOB_SKILL,
  UPDATE_KEYWORD,
  UPDATE_NUMBER,
  UPDATE_SORT,
  UPDATE_VIEW_MODE
} from "@/store/context/types/JobFilterType";

export type JobFilterState = {
  readonly keyword: string;
  readonly sort: {value: string, text: string};
  readonly jobCategory: {_id: string, vi_title: string, en_title: string};
  readonly jobLocation: {_id: string, vi_title: string, en_title: string};
  readonly jobSkill: {_id: string, title: string};
  readonly benefit: {_id: string, vi_title: string, en_title: string};
  readonly number: number;
  readonly viewMode: "grid" | "list";
};
export const JobFilterStateInit: JobFilterState = {
  keyword: "",
  sort: {
    value: "created_newest",
    text: "Mới nhất"
  },
  jobCategory: {
    _id: "",
    en_title: "",
    vi_title: "",
  },
  jobLocation: {
    _id: "",
    en_title: "",
    vi_title: "",
  },
  jobSkill: {
    _id: "",
    title: "",
  },
  benefit: {
    _id: "",
    en_title: "",
    vi_title: "",
  },
  number: 0,
  viewMode: "list"
};
export type JobFilterAction = {type: string, value: any}

export interface IJobFilterContext {
  state: JobFilterState;
  dispatch: Dispatch<JobFilterAction>
}

export type IJobFilterReducer = Reducer<JobFilterState, JobFilterAction>;

export const JobFilterReducer: IJobFilterReducer = (state: JobFilterState, action: JobFilterAction) => {
  switch (action.type) {
    case UPDATE_KEYWORD:
      return updateObject<JobFilterState>(state, {keyword: action.value});
    case UPDATE_JOB_CATEGORY:
      return updateObject<JobFilterState>(state, {jobCategory: action.value});
    case UPDATE_JOB_LOCATION:
      return updateObject<JobFilterState>(state, {jobLocation: action.value});
    case UPDATE_JOB_SKILL:
      return updateObject<JobFilterState>(state, {jobSkill: action.value});
    case UPDATE_BENEFIT:
      return updateObject<JobFilterState>(state, {benefit: action.value});
    case UPDATE_SORT:
      return updateObject<JobFilterState>(state, {sort: action.value});
    case UPDATE_NUMBER:
      return updateObject<JobFilterState>(state, {number: action.value});
    case UPDATE_VIEW_MODE:
      return updateObject<JobFilterState>(state, {viewMode: action.value});
    default:
      return state;
  }
};

export const JobFilterContext = createContext<IJobFilterContext | any>(null);
