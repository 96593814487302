import {combineReducers} from "redux";

import {jobPageReducer} from "@/store/redux/reducers/JobPageReducer";
import {resumeCreateReducer} from "@/store/redux/reducers/ResumeCreateReducer";

import {GlobalToastReducer} from "@/store/redux/reducers/GlobalToastReducer";
import {GlobalModalReducer} from "@/store/redux/reducers/GlobalModalReducer";

const rootReducer = combineReducers({
  jobPage: jobPageReducer,
  resumeCreatePage: resumeCreateReducer,
  globalToast: GlobalToastReducer,
  globalModal: GlobalModalReducer,
});

export default rootReducer;
