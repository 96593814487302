import JobPageType from "@/store/redux/types/JobPageType";

export const fetchingJob = (payload: any) => ({ type: JobPageType.FETCHING_JOB, payload });
export const fetchJobSuccess = (payload: any) => ({ type: JobPageType.FETCH_JOB_SUCCESS, payload: payload });
export const updateNumber = (payload: any) => ({ type: JobPageType.UPDATE_NUMBER, payload: { number: payload } });
export const updateKeyword = (payload: any) => ({ type: JobPageType.UPDATE_KEYWORD, payload: { keyword: payload } });
export const updateCategory = (payload: any) => ({
  type: JobPageType.UPDATE_JOB_CATEGORY,
  payload: { jobCategory: payload },
});
export const updateLocation = (payload: any) => ({
  type: JobPageType.UPDATE_JOB_LOCATION,
  payload: { jobLocation: payload },
});
export const updateSkill = (payload: any) => ({
  type: JobPageType.UPDATE_JOB_SKILL,
  payload: { jobSkill: payload },
});
export const updateBenefit = (payload: any) => ({
  type: JobPageType.UPDATE_BENEFIT,
  payload: { benefit: payload },
});
export const updateView = (payload: any) => ({
  type: JobPageType.UPDATE_VIEW_MODE,
  payload: { viewMode: payload },
});
export const updateSort = (payload: any) => ({
  type: JobPageType.UPDATE_SORT,
  payload: { sort: payload },
});
export const updatePage = (payload: any) => ({
  type: JobPageType.UPDATE_PAGE,
  payload: { page: payload },
});
