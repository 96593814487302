import { updateObject } from "@/store/utility";
import ResumeCreateType, { IResumeCreateState } from "@/store/redux/types/ResumeCreateType";
import { v4 as uuidv4 } from "uuid";

const initState: IResumeCreateState = {
  theme_id: "",
  activeTheme_id: "",
  info: {
    info_resumeTitle: "",
    info_name: "",
    info_birthday: "",
    info_email: "",
    info_phone: "",
    info_city: "",
    info_province: "",
    info_zipcode: "",
    info_address: "",
  },
  education: [
    {
      id: uuidv4(),
      education_school: "",
      education_course: "",
      education_specialized: "",
      education_detail: "",
    },
  ],
  target: {
    target_short: "",
    target_long: "",
    target_detail: "",
  },
  interest: [
    {
      id: uuidv4(),
      interest_name: "",
    },
  ],
  experience: [
    {
      id: uuidv4(),
      experience_time: "",
      experience_company: "",
      experience_title: "",
      experience_detail: "",
    },
  ],
  skill: [
    {
      id: uuidv4(),
      skill_title: "",
      skill_level: null,
    },
  ],
  person: [
    {
      id: uuidv4(),
      person_name: "",
      person_company: "",
      person_email: "",
      person_phone: "",
    },
  ],
};
export const resumeCreateReducer = (state = initState, action: any) => {
  switch (action.type) {
    case ResumeCreateType.CHANGE_THEME:
      return {
        ...state,
        theme_id: action.payload,
      };
    case ResumeCreateType.SELECTED_THEME:
      return {
        ...state,
        activeTheme_id: action.payload,
      };
    case ResumeCreateType.UPDATE_INFO:
      return updateObject<IResumeCreateState>(state, action.payload);
    case ResumeCreateType.UPDATE_EDUCATION:
      return updateObject<IResumeCreateState>(state, action.payload);
    case ResumeCreateType.UPDATE_TARGET:
      return updateObject<IResumeCreateState>(state, action.payload);
    case ResumeCreateType.UPDATE_INTEREST:
      return updateObject<IResumeCreateState>(state, action.payload);
    case ResumeCreateType.UPDATE_EXPERIENCE:
      return updateObject<IResumeCreateState>(state, action.payload);
    case ResumeCreateType.UPDATE_SKILL:
      return updateObject<IResumeCreateState>(state, action.payload);
    case ResumeCreateType.UPDATE_PERSON:
      return updateObject<IResumeCreateState>(state, action.payload);
    case ResumeCreateType.ADD_EDUCATION:
      return {
        ...state,
        education: [
          ...state.education.slice(0, action.payload + 1),
          {
            id: uuidv4(),
            education_school: "",
            education_course: "",
            education_specialized: "",
            education_detail: "",
          },
          ...state.education.slice(action.payload + 1),
        ],
      };
    case ResumeCreateType.ADD_EXPERIENCE:
      console.log(uuidv4());
      return {
        ...state,
        experience: [
          ...state.experience.slice(0, action.payload + 1),
          {
            id: uuidv4(),
            experience_time: "",
            experience_company: "",
            experience_title: "",
            experience_detail: "",
          },
          ...state.experience.slice(action.payload + 1),
        ],
      };
    case ResumeCreateType.ADD_SKILL:
      return {
        ...state,
        skill: [
          ...state.skill.slice(0, action.payload + 1),
          {
            id: uuidv4(),
            skill_title: "",
            skill_level: "",
          },
          ...state.skill.slice(action.payload + 1),
        ],
      };
    case ResumeCreateType.ADD_PERSON:
      return {
        ...state,
        person: [
          ...state.person.slice(0, action.payload + 1),
          {
            id: uuidv4(),
            person_name: "",
            person_company: "",
            person_email: "",
            person_phone: "",
          },
          ...state.person.slice(action.payload + 1),
        ],
      };
    case ResumeCreateType.ADD_INTEREST:
      return {
        ...state,
        interest: [
          ...state.interest.slice(0, action.payload + 1),
          {
            id: uuidv4(),
            interest_name: "",
          },
          ...state.interest.slice(action.payload + 1),
        ],
      };
    default:
      return state;
  }
};
