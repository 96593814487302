import React, { ComponentType, useState } from "react";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { compose } from "recompose";
import ReactLink from "@/components/Link/ReactLink";
import { apolloClient } from "@/utils/apolloClient";
import { UPDATE_JOB_SAVE } from "@/graphql/job_save/mutation";
import { withCookies } from "react-cookie";
import Cookies from "universal-cookie";
import { cloudFrontImageUrl } from "@/helpers/string";

interface IProps {
  cookies: Cookies;
  view?: string;
  job: IJob;
}

interface IJob {
  _id: string;
  title: string;
  slug: string;
  image: string;
  name: string;
  location: string;
  salary: string;
  like_status: boolean;
  address: string;
  view_count: number;
  created_at: string;
  updated_at: string;
}

const useStyles = makeStyles({
  root: {
    border: "none",
    boxShadow: "none",
  },
  media: {
    height: 140,
  },
});

const CardJob: ComponentType<IProps> = (props: IProps) => {
  const classes = useStyles();
  const { cookies } = props;
  const {
    view = "list",
    job: { _id, title, slug, image, name, location, salary, address, view_count, created_at, updated_at },
  } = props;
  const [like, setLike] = useState(false);
  const [add, setAdd] = useState(true);
  console.log("address", address);
  const handleToggleLike = () => {
    setLike((prevLike) => !prevLike);
  };

  const handleToggleAdd = () => {
    // setAdd((prevAdd) => !prevAdd);
    if (cookies.get("knv_accessToken")) {
      apolloClient.mutate({ mutation: UPDATE_JOB_SAVE, variables: { input: { job_post: _id } } }).then((r) => {
        alert("Đã lưu tin tuyển dụng");
      });
    }
  };

  return (
    <div>
      {view === "list" && (
        <div className="card__job">
          <img alt={title} src={cloudFrontImageUrl(image)} className="card__job__image" />
          <div className="content">
            <div className="content__info">
              <ReactLink url={`${process.env.REACT_APP_JOB_CATE_URL}/${slug}`}>
                <b className="content__info__title">{title}</b>
              </ReactLink>
              <div className="content__info__company">{name}</div>
              <div className="content__info__location">
                {/*<img alt="" src={IconLocation} style={{width: 14, height: 18, marginRight: 5}}/>*/}
                <span> {address}</span>
              </div>
              <div className="content__info__salary">
                {/* <img alt="" src={IconMoney} style={{ width: 14, height: 16, marginRight: 5 }} /> */}
                <span>{salary}</span>
              </div>
            </div>
            <div className="content__buttons">
              <Button onClick={() => {}} className="content__buttons__compare">
                So sánh
              </Button>
              <div className="content__buttons__bottom">
                <div className="content__buttons__bottom__group">
                  {/*<Button*/}
                  {/*  className={`content__buttons__bottom__group__like ${*/}
                  {/*    like ? "content__buttons__bottom__group__like--active" : ""*/}
                  {/*  }`}*/}
                  {/*  onClick={handleToggleLike}*/}
                  {/*>*/}
                  {/*  <FavoriteRoundedIcon style={{fontSize: 11, color: "#fff"}}/>*/}
                  {/*</Button>*/}
                  <Button
                    className={`content__buttons__bottom__group__add  ${
                      add ? "content__buttons__bottom__group__add--active" : ""
                    }`}
                    onClick={handleToggleAdd}
                  >
                    <AddRoundedIcon style={{ fontSize: 14, color: "#fff" }} />
                  </Button>
                </div>
                <span className="content__buttons__bottom__expired__date">Hết hạn: 20/05/2020</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {view === "grid" && (
        <div className="card__job__grid">
          <Card className={classes.root}>
            <CardMedia image={cloudFrontImageUrl(image)} className={classes.media} />
            <CardContent>
              <div className="content">
                <div className="content__info">
                  <ReactLink url={`${process.env.REACT_APP_JOB_CATE_URL}/${slug}`}>
                    <b className="content__info__title">{title}</b>
                  </ReactLink>
                  <div className="content__info__company">{name}</div>
                  <div className="content__info__location">
                    {/*<img alt="" src={IconLocation} style={{width: 14, height: 18, marginRight: 5}}/>*/}
                    <span>{address}</span>
                  </div>
                  <div className="content__info__salary__grid">
                    <div className="content__info__salary__grid__text ">
                      {/* <img alt="" src={IconMoney} style={{ width: 14, height: 16, marginRight: 5 }} /> */}
                      <span>{salary}</span>
                    </div>
                    <Button onClick={() => {}} className="content__info__salary__grid__compare">
                      So sánh
                    </Button>
                  </div>
                </div>
                <div className="content__buttons">
                  <div className="content__buttons__bottom">
                    <div className="content__buttons__bottom__group">
                      {/*<Button*/}
                      {/*  className={`content__buttons__bottom__group__like ${*/}
                      {/*    like ? "content__buttons__bottom__group__like--active" : ""*/}
                      {/*  }`}*/}
                      {/*  onClick={handleToggleLike}*/}
                      {/*>*/}
                      {/*  <FavoriteRoundedIcon style={{fontSize: 11, color: "#fff"}}/>*/}
                      {/*</Button>*/}
                      <Button
                        className={`content__buttons__bottom__group__add  ${
                          add ? "content__buttons__bottom__group__add--active" : ""
                        }`}
                        onClick={handleToggleAdd}
                      >
                        <AddRoundedIcon style={{ fontSize: 14, color: "#fff" }} />
                      </Button>
                    </div>
                    <span className="content__buttons__bottom__expired__date">Hết hạn: 20/05/2020</span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default compose<IProps, any>(withCookies)(CardJob);
