enum ResumeCreateType {
  CHANGE_THEME = "@@ResumeCreatePage/CHANGE_THEME",
  SELECTED_THEME = "@@ResumeCreatePage/SELECTED_THEME",
  UPDATE_INFO = "@@ResumeCreatePage/UPDATE_INFO",
  UPDATE_EDUCATION = "@@ResumeCreatePage/UPDATE_EDUCATION",
  UPDATE_TARGET = "@@ResumeCreatePage/UPDATE_TARGET",
  UPDATE_INTEREST = "@@ResumeCreatePage/UPDATE_INTEREST",
  UPDATE_EXPERIENCE = "@@ResumeCreatePage/UPDATE_EXPERIENCE",
  UPDATE_SKILL = "@@ResumeCreatePage/UPDATE_SKILL",
  UPDATE_PERSON = "@@ResumeCreatePage/UPDATE_PERSON",
  ADD_EXPERIENCE = "@@ResumeCreatePage/ADD_EXPERIENCE",
  ADD_EDUCATION = "@@ResumeCreatePage/ADD_EDUCATION",
  ADD_SKILL = "@@ResumeCreatePage/ADD_SKILL",
  ADD_PERSON = "@@ResumeCreatePage/ADD_PERSON",
  ADD_INTEREST = "@@ResumeCreatePage/ADD_INTEREST",
}

interface IResumeCreateState {
  readonly theme_id: string;
  readonly activeTheme_id: string,
  readonly info: {
    info_resumeTitle: string;
    info_name: string;
    info_birthday: string;
    info_email: string;
    info_phone: string;
    info_city: string;
    info_province: string;
    info_zipcode: string;
    info_address: string;
  };
  readonly education: any[];
  readonly target: { target_short: string; target_long: string; target_detail: string };
  readonly interest: any[];
  readonly experience: any[];
  readonly skill: any[];
  readonly person: any[];
}
export default ResumeCreateType;
export { IResumeCreateState };
