import React, { Fragment } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";

interface IProps {
  view?: string;
}

const useStyles = makeStyles({
  root: {
    border: "none",
    boxShadow: "none",
  },
});

const CardJobSkeleton = (props: IProps) => {
  const { view = "list" } = props;
  const classes = useStyles();

  return (
    <Fragment>
      {view === "list" && (
        <div className="skeleton__card__job">
          <div className="skeleton__card__job__image">
            <Skeleton variant="rect" width={140} height={140} />
          </div>
          <div className="skeleton__card__job__content">
            <Skeleton variant="rect" height={70} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </div>
          <div className="skeleton__card__job__actions">
            <Skeleton variant="rect" height={30} />
            <div>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </div>
          </div>
        </div>
      )}
      {view === "grid" && (
        <div className="skeleton__card__job__grid">
          <Card className={classes.root}>
            <CardMedia>
              <Skeleton variant="rect" height={140} />
            </CardMedia>
            <CardContent>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </CardContent>
          </Card>
        </div>
      )}
    </Fragment>
  );
};

export default CardJobSkeleton;
