export const getSort = (sortValue: string) => {
  switch (sortValue) {
    case "created_newest":
      return { created: "newest" };
    case "created_oldest":
      return { created: "oldest" };
    case "salary_low_to_high":
      return { salary: "low_to_high" };
    case "salary_high_to_low":
      return { salary: "high_to_low" };
    case "view_count_low_to_high":
      return { view_count: "low_to_high" };
    case "view_count_high_to_low":
      return { view_count: "high_to_low" };
    case "rating_high_to_low":
      return { rating: "high_to_low" };
    default:
      return { created: "newest" };
  }
};

export const graphqlFilter = (filter: object) => {
  return JSON.stringify(filter).replace(/"/g, `'`);
};

export const formatMoneyNumberToString = (num: any) => {
  if (!num) {
    return num;
  }

  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const cloudFrontImageUrl = (imageUrl: string) => {
  if (!imageUrl) return "https://files.exam24h.com/upload/2024/09/24_1727145421421/66f225bc2fdabac26581b0b7/default.png";
  imageUrl = imageUrl.replace(/ketnoiviec.s3.ap-southeast-1.amazonaws.com/gi, "static.ketnoiviec.net");
  imageUrl = imageUrl.replace(/ketnoiviec.s3-ap-southeast-1.amazonaws.com/gi, "static.ketnoiviec.net");
  imageUrl = imageUrl.replace(/ketnoiviec.s3.amazonaws.com/gi, "static.ketnoiviec.net");
  imageUrl = imageUrl.replace(/http:\/\//gi, "https://");
  return imageUrl;
};
