import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import JobSlideGridItem from "@/components/Grid/JobSlideGridItem";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { apolloClient } from "@/utils/apolloClient";
import { PAGINATE_JOB_POST } from "@/graphql/job_post/query";
import { formatMoneyNumberToString } from "@/helpers/string";

interface IProps {
  classes: any;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
  });

const ListSlideJob = (props: IProps) => {
  const { classes } = props;

  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    apolloClient
      .query({ query: PAGINATE_JOB_POST, variables: { page: 1, limit: 12, filter: "{'status': 'active'}" } })
      .then((r) => {
        if (r.data.jobPosts.edges) {
          setData(r.data.jobPosts.edges);
          console.log(r.data.jobPosts.edges);
        }
      });
  }, []);

  return (
    <div className={classes.root}>
      <Slider {...settings} className={classes.wrapSlide}>
        {data.map((item: any, index: number) => {
          let title = item.node.title;
          let slug = item.node.slug;
          let company_logo = item.node.company.ref.logo;
          let company_name = item.node.company.name;
          let address = item.node.company.ref.office[0]?.address || "";
          let salary = item.node.salary;
          let view_count = item.node.view_count;
          let created_at = item.node.created_at;
          let updated_at = item.node.updated_at;

          let dataItem = {
            title: title,
            slug: slug,
            image: company_logo,
            location: address,
            company_name: company_name,
            salary: salary
              ? `${formatMoneyNumberToString(salary.min)} ₫ - ${formatMoneyNumberToString(salary.max)} ₫`
              : "Thương lượng",
            like_status: false,
            view_count: view_count,
            created_at: created_at,
            updated_at: updated_at,
          };
          return (
            <JobSlideGridItem
              key={index}
              title={dataItem.title}
              slug={dataItem.slug}
              location={dataItem.location}
              image={dataItem.image}
              company_name={dataItem.company_name}
              salary={dataItem.salary}
              like_status={dataItem.like_status}
              view_count={dataItem.view_count}
              created_at={dataItem.created_at}
              updated_at={dataItem.updated_at}
            />
          );
        })}
      </Slider>
    </div>
  );
};

export default compose<IProps, any>(withStyles(styles))(ListSlideJob);
