import React, { ComponentType, Fragment } from "react";
import { createStyles, Theme, withStyles, IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { compose } from "recompose";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "@/store/redux/actions/GlobalModal";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
  classes: any;
}
const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
  });
const GlobalModal: ComponentType<IProps> = (props: IProps) => {
  const { classes } = props;
  const { children, isOpen } = useSelector((state: any) => state.globalModal);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  return (
    <Dialog maxWidth="md" fullScreen={fullScreen} fullWidth open={isOpen} onClose={() => dispatch(closeModal())}>
      <DialogTitle>
        <IconButton size="small" className={classes.closeButton} onClick={() => dispatch(closeModal())}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Fragment>{children.modal && children.modal}</Fragment>
      </DialogContent>
    </Dialog>
  );
};
export default compose<IProps, any>(withStyles(styles))(GlobalModal);
