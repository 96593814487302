import React from "react";
import { cloudFrontImageUrl } from "@/helpers/string";

interface IProps {
  src: string;
  className?: string;
  alt?: string;
}

const Image = (props: IProps) => {
  const { src, alt, ...rest } = props;
  return <img alt={alt} src={cloudFrontImageUrl(src)} {...rest} />;
};

export default Image;
