import React, { ComponentType, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import FancyRoute from "@/routes/FancyRouter";
import JobPage from "@/views/JobPage/Job";

const HomePage = lazy(() => import("@/views/HomePage/Home"));
const AboutPage = lazy(() => import("@/views/AboutPage/About"));
const ProfilePage = lazy(() => import("@/views/ProfilePage/Profile"));
const JobAlertPage = lazy(() => import("@/views/JobAlertPage/JobAlert"));
const ViewListProfilePage = lazy(() => import("@/views/ProfileViewPage/ProfileView"));
const MyJobPage = lazy(() => import("@/views/MyJobPage/MyJob"));
const CompanyPage = lazy(() => import("@/views/CompanyPage/Company"));
const DetailCompanyPage = lazy(() => import("@/views/DetailCompanyPage/DetailCompany"));
const EditProfilePage = lazy(() => import("@/views/EditProfilePage/EditProfile"));
const SettingPage = lazy(() => import("@/views/SettingPage/Setting"));
const JobPostPage = lazy(() => import("@/views/JobPostPage/JobPost"));
const PricingPage = lazy(() => import("@/views/PricingPage/Pricing"));
const FbConnectPage = lazy(() => import("@/views/ConnectPage/FbConnect"));
const LandingPage = lazy(() => import("@/views/LandingPage/Landing"));
const PremiumPage = lazy(() => import("@/views/PremiumPage/Premium"));
const ProfileStepByStep = lazy(() => import("@/views/ProfileStepByStep/ProfileStepByStep"));
// const StatisticPage = lazy(() => import("@/views/StatisticPage/Statistic"));
const DetailStatisticPage = lazy(() => import("@/views/DetailStatisticPage/DetailStatistic"));
const ResumeListPage = lazy(() => import("@/views/ResumePage/ResumeListPage/ResumeList"));
const ResumeCreatePage = lazy(() => import("@/views/ResumePage/ResumeCreatePage/ResumeCreate"));

const SuspenseComponent = (Component: any) => {
  return (props: any) => (
    <Suspense fallback={<div />}>
      <Component {...props} />
    </Suspense>
  );
};
const routes = [
  {
    title: "Home page",
    exact: true,
    path: "/",
    component: SuspenseComponent(HomePage),
  },
  {
    title: "Landing page",
    exact: true,
    path: process.env.REACT_APP_JOIN_RECRUITMENT_URL,
    component: SuspenseComponent(LandingPage),
  },
  {
    title: "About us page",
    exact: true,
    path: process.env.REACT_APP_ABOUT_US_URL,
    component: SuspenseComponent(AboutPage),
  },
  {
    title: "Company page",
    exact: true,
    path: process.env.REACT_APP_COMPANY_URL,
    component: SuspenseComponent(CompanyPage),
  },
  {
    title: "Job page",
    exact: true,
    path: process.env.REACT_APP_JOB_CATE_URL,
    component: SuspenseComponent(JobPage),
  },
  {
    title: "My job page",
    exact: true,
    path: process.env.REACT_APP_MY_JOB_URL,
    component: SuspenseComponent(MyJobPage),
  },
  {
    title: "Edit profile page",
    exact: true,
    path: process.env.REACT_APP_ME_URL,
    component: SuspenseComponent(EditProfilePage),
  },
  {
    title: "Edit profile step by step",
    exact: true,
    path: process.env.REACT_APP_ME_STEP_URL,
    component: SuspenseComponent(ProfileStepByStep),
  },
  {
    title: "Profile page",
    exact: true,
    path: process.env.REACT_APP_PROFILE_URL,
    component: SuspenseComponent(ProfilePage),
  },
  {
    title: "Job alert page",
    exact: true,
    path: process.env.REACT_APP_JOB_NOTIFICATION_URL,
    component: SuspenseComponent(JobAlertPage),
  },
  {
    title: "Profile view page",
    exact: true,
    path: process.env.REACT_APP_PROFILE_VIEW_URL,
    component: SuspenseComponent(ViewListProfilePage),
  },
  {
    title: "Setting page",
    exact: true,
    path: process.env.REACT_APP_SETTING_URL,
    component: SuspenseComponent(SettingPage),
  },
  {
    title: "Facebook connect page",
    exact: true,
    path: process.env.REACT_APP_FB_CONNECT_URL,
    component: SuspenseComponent(FbConnectPage),
  },
  {
    title: "Pricing page",
    exact: true,
    path: process.env.REACT_APP_PRICING_URL,
    component: SuspenseComponent(PricingPage),
  },
  {
    title: "Company detail page",
    exact: true,
    path: `${process.env.REACT_APP_COMPANY_URL}/:slug`,
    component: SuspenseComponent(DetailCompanyPage),
  },
  {
    title: "Job detail page",
    exact: true,
    path: `${process.env.REACT_APP_JOB_CATE_URL}/:slug`,
    component: SuspenseComponent(JobPostPage),
  },
  {
    title: "Premium company detail page",
    exact: true,
    path: `${process.env.REACT_APP_PREMIUM_CHANNEL}/:slug`,
    component: SuspenseComponent(PremiumPage),
  },
  // {
  //   title: "Statistic Page",
  //   exact: true,
  //   path: process.env.REACT_APP_STATISTIC_URL,
  //   component: SuspenseComponent(StatisticPage),
  // },
  {
    title: "Detail Statistic Page",
    exact: false,
    path: `${process.env.REACT_APP_STATISTIC_URL}/:slug`,
    component: SuspenseComponent(DetailStatisticPage),
  },
  {
    title: "CV List detail page",
    exact: true,
    path: process.env.REACT_APP_CV_URL,
    component: SuspenseComponent(ResumeListPage),
  },
  {
    title: "Create CV detail page",
    exact: false,
    path: `${process.env.REACT_APP_CV_URL}/:slug`,
    component: SuspenseComponent(ResumeCreatePage),
  },
];
const RouteList: ComponentType<{}> = () => {
  return (
    <Switch>
      {routes.map((r: any, i: number) => (
        <FancyRoute key={i} {...r} />
      ))}
      <Route
        path={"/:slug"}
        exact
        render={({ match }) => {
          if (!RegExp(`${process.env.REACT_APP_JOB_CATE_URL}`, "i").test(`/${match.params.slug}`)) {
            return <JobPage />;
          } else return <JobPage />;
        }}
      />
    </Switch>
  );
};

export default RouteList;
