import {all, fork} from "redux-saga/effects";
import JobPageSaga from "@/store/redux/sagas/JobPageSaga";

function* rootSaga() {
  yield all([
    fork(JobPageSaga),
  ]);
}

export default rootSaga;
