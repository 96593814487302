import gql from "graphql-tag";

export const PAGINATE_SUGGESTION = gql`
    query suggestions ($page: Int!, $limit: Int!, $filter: String!) {
        suggestions (page: $page, filter: $filter, limit: $limit) {
            edges {
                node {
                    title
                }
            }
        }
    }
`;
