import gql from "graphql-tag";

export const PAGINATE_JOB_POST = gql`
  query paginateJobPost($page: Int!, $limit: Int!, $filter: String!) {
    jobPosts(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          title
          slug
          job_level {
            _id
            title
          }
          job_type {
            _id
            title
          }
          image
          job_category {
            _id
            title
          }
          number
          description
          requirement
          salary {
            min
            max
            show
          }
          company {
            ref {
              name
              logo
              office {
                address
              }
            }
            name
          }
          status
          view_count
          end_date
          created_at
          updated_at
        }
      }
      pageInfo {
        length
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const POPULAR_JOB_POST = gql`
  query popularJobPost($page: Int!, $limit: Int!, $filter: String!) {
    jobPosts(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          title
          slug
          job_level {
            _id
            title
          }
          job_type {
            _id
            title
          }
          image
          job_category {
            _id
            title
          }
          number
          description
          requirement
          salary {
            min
            max
            show
          }
          company {
            ref {
              logo
              cover
              office {
                address
              }
            }
            name
          }
          status
          view_count
          end_date
          created_at
          updated_at
        }
      }
      pageInfo {
        length
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const SINGLE_JOB_POST = gql`
  query singleJobPost($slug: String!) {
    jobPost(slug: $slug) {
      _id
      title
      description
      requirement
      slug
      job_level {
        _id
        title
        slug
        seo_title
        seo_description
      }
      job_category {
        _id
        title
        slug
        seo_title
        seo_description
      }

      company {
        ref {
          name
          size {
            to
            from
          }
          description
          logo
          cover
          album {
            images {
              description
              src
            }
            name
          }
          office {
            address
            city {
              name
            }
          }
          benefit {
            content
            id {
              title
              slug
              icon
            }
          }
        }
      }
      salary {
        min
        max
        show
      }
      job_type {
        title
      }
      end_date
      view_count
      created_at
      updated_at
    }
  }
`;
