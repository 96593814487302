import React, { ComponentType } from "react";
import Grid from "@material-ui/core/Grid";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import Hidden from "@material-ui/core/Hidden";
import WrapperContainer from "@/components/WrapperContainer/WrapperContainer";
import bct from '../../assets/images/bocongthuong.png'
import visa from '../../assets/images/payment/visa.png'
import master from '../../assets/images/payment/master.png'
import atm from '../../assets/images/payment/atm.png'
import googlePlay from '../../assets/images/google.svg'
import apple from '../../assets/images/apple.svg'
const Footer: ComponentType = () => (
  <WrapperContainer fullWidth>
    <Grid
      container
      spacing={0}
      item
      xs={12}
      className="footer"
      direction="row"
      justify="center"
      alignItems="center"
      style={{ marginTop: "4em" }}
    >
      <Grid container spacing={0} className="footer_body" justify="center">
        <Grid item md={4} sm={12} className="footer_body__item border_bottom_mobile">
          <div className="footer_body__item_title">CÔNG TY CỔ PHẦN IKIGROUP</div>
          <div className="footer_body__item_description">
            <div className="footer_body__item_description_icon">
              <svg viewBox="0 0 384 512">
                <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
              </svg>
            </div>
            <div className="footer_body__item_description_text">
              <p className="footer_body__item_description_text_title">Địa chỉ</p>
              <p className="footer_body__item_description_text_content">
                Tầng 9, Tòa IDS đường Phạm Hùng, Phường Mai Dịch, Quận Cầu Giấy, Tp Hà Nội.
              </p>
            </div>
          </div>
          <div className="footer_body__item_description">
            <div className="footer_body__item_description_icon">
              <PhoneIphone />
            </div>
            <div className="footer_body__item_description_text">
              <p className="footer_body__item_description_text_title">Điện thoại</p>
              <p className="footer_body__item_description_text_content">09846.35577</p>
            </div>
          </div>
          <div className="footer_body__item_branch">
            <img
              className="gov"
              src={bct}
              alt="Đã thông báo với Bộ Công Thương"
            />
            {/* <a
              href={
                "https://www.dmca.com/Protection/Status.aspx?ID=7816605d-fbd2-45f8-a397-eeac42642b75&refurl=https://ketnoiviec.net/"
              }
              rel={"nofollow"}
              target={"_blank"}
            >
              <img src="https://static.docs.jobid.vn/default/dmca.png" alt="DMCA" className="dmca" />
            </a> */}
          </div>
        </Grid>
        <Grid item md={8} sm={12}>
          <Grid container spacing={0}>
            <Grid item md={3} xs={6} className="footer_body__item">
              <div className="footer_body__item_title">Công ty</div>
              <div className="footer_body__item_description">
                <a href={process.env.REACT_APP_ABOUT_US_URL}>Về JobID</a>
              </div>
              <div className="footer_body__item_description">
                <a href={process.env.REACT_APP_JOIN_RECRUITMENT_URL}>Tuyển dụng</a>
              </div>
              <div className="footer_body__item_description">
                <a href={process.env.REACT_APP_ABOUT_US_URL}>Đội ngũ</a>
              </div>
              <div className="footer_body__item_description">
                <a href={process.env.REACT_APP_ABOUT_US_URL}>Thông tin liên hệ</a>
              </div>
            </Grid>
            <Grid item md={3} xs={6} className="footer_body__item">
              <div className="footer_body__item_title">Điều khoản</div>
              <div className="footer_body__item_description">
                <a href={"https://docs.jobid.vn/dieu-khoan/quy-che-hoat-dong"}>Quy chế hoạt động</a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://docs.jobid.vn/dieu-khoan/chinh-sach-bao-mat"}>Chính sách bảo mật</a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://docs.jobid.vn/dieu-khoan/chinh-sach-cookie"}>Chính sách Cookie</a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://docs.jobid.vn/dieu-khoan/dieu-khoan-su-dung"}>Điều khoản sử dụng</a>
              </div>
            </Grid>
            <Grid item md={3} xs={6} className="footer_body__item">
              <div className="footer_body__item_title">Thông tin</div>
              <div className="footer_body__item_description">
                <a href={"https://docs.jobid.vn/tin-tuc/tin-tuc"}>Tin tức tuyển dụng</a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://docs.jobid.vn/tin-tuc/blog"}>Báo cáo & Phân tích</a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://docs.jobid.vn/tin-tuc/kien-thuc-moi-gioi"}>Kiến thức cho tuyển dụng</a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://docs.jobid.vn/huong-dan"}>Hướng dẫn sử dụng</a>
              </div>
            </Grid>
            <Grid item md={3} xs={6} className="footer_body__item">
              <div className="footer_body__item_title">Mạng xã hội</div>
              <div className="footer_body__item_description">
                <a href={"https://www.facebook.com/docs.jobid.vn/"} rel={"nofollow"}>
                  Facebook
                </a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://www.youtube.com/channel/UC3SwlawYnWGWDvpVyyo6ZMA"} rel={"nofollow"}>
                  Youtube
                </a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://twitter.com/jobid"} rel={"nofollow"}>
                  Twitter
                </a>
              </div>
              <div className="footer_body__item_description">
                <a href={"https://www.linkedin.com/in/jobid/"} rel={"nofollow"}>
                  LinkedIn
                </a>
              </div>
            </Grid>
            <Grid item md={6} xs={12} className="footer_body__apps">
              <div className="footer_body__apps_title">Phương thức thanh toán</div>
              <div className="footer_body__apps_payment">
                <img className="no_border" src={visa} alt="Visa" />
                <img className="no_border" src={master} alt="Mastercard" />
                <img className="no_border" src={atm} alt="ATM" />
              </div>
            </Grid>
            <Grid item md={6} xs={12} className="footer_body__apps">
              <div className="footer_body__apps_title">Ứng dụng di động</div>
              <div className="footer_body__apps_payment">
                <img
                  className="no_border"
                  src={apple}
                  alt="Apple Store"
                />
                <img
                  className="no_border"
                  src={googlePlay}
                  alt="Google Play"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="footer_bottom" justify="center">
        <Hidden smDown>
          <Grid item xs={6} className="footer_bottom__left">
            © 2024 - Bản quyền của Công ty Cổ phần IKIGROUP
          </Grid>
          <Grid item xs={6} className="footer_bottom__right">
            <a href="/">Phản hồi</a>
            <a href="/">Điều khoản</a>
            <a href="/">Chính sách bảo mật</a>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid item xs={12} className="footer_bottom__center">
            © 2024 - Bản quyền của Công ty Cổ phần IKIGROUP
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  </WrapperContainer>
);

export default Footer;
